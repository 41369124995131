import React from 'react';
import PropTypes from 'prop-types';

const BackgroundHighlight = ({ backgroundColor }) => (
  <div className="background-highlight" style={{ backgroundColor }} />
);

BackgroundHighlight.propTypes = {
  backgroundColor: PropTypes.string.isRequired
};

export default BackgroundHighlight;
